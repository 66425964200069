import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import TitleStyle from "../components/TitleStyle/TitleStyle"
import Content from "../components/Content/Content"
import Seo from "../components/SEO/SEO"
import FlexDiv from "../components/FlexDiv/FlexDiv"
import ButtonStyle from "../components/ButtonStyle/ButtonStyle"
import LinkStyle from "../components/LinkStyle/LinkStyle"
import ProductDetails from "../components/ProductDetails/ProductDetails"
import SubtitleStyle from "../components/SubtitleStyle/SubtitleStyle"

const Template = ({ data }) => {
    const { markdownRemark } = data
    const { frontmatter, html } = markdownRemark

    return(
        <Layout>
    <Seo title={frontmatter.title} />
      <Content>
        <TitleStyle>{frontmatter.title}</TitleStyle>
        <SubtitleStyle isSmall>{frontmatter.subtitle}</SubtitleStyle>
        <div>
            <p>Zawiera substancje czynne, ziołowe które: łagodzą stany zapalne, zmniejszają bóle mięśni, stawów i chrząstek, chłodzą powierzchnię skóry i zwiększają przepływ krwi, wzmacniają wchłanianie substancji czynnych i wchłanianie do głębokich warstw skóry.</p>
        </div>
        <FlexDiv justify='space-evenly' align='center'>
            <img height='350px' src={frontmatter.imageUrl} alt={frontmatter.title} />
            <div>
                <p>{frontmatter.price} zł</p>
                <LinkStyle as='a' href='https://allegro.pl/uzytkownik/MercePoland' target='_blank'><ButtonStyle>KUP</ButtonStyle></LinkStyle>
            </div>
        </FlexDiv>
        <ProductDetails dangerouslySetInnerHTML={{ __html: html }} />
      </Content>
    </Layout>
    )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        title
        subtitle
        short
        imageUrl
        price
      }
    }
  }
`

export default Template