import styled from 'styled-components'

const ProductDetails = styled.div`
    a {
        font-weight: 700;
        color: inherit;
        text-decoration: none;
    }
`

export default ProductDetails